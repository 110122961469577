var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/AboutPress/AboutPress.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/AboutPress/AboutPress.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += "\n    <div class=\"AboutPress isAnimated\">\n        <div class=\"AboutPress-inner\">\n            <div class=\"AboutPress-title\">Press</div>\n            <p class=\"AboutPress-text\">For press inquiries please contact Danil Krivoruchko via <a href=\"mailto: danil@myshli.com\" target=\"_blank\" class=\"AboutPress-link\">→ email</a></p>\n            <div class=\"AboutPress-image\">\n                <img src=\"";
t_5 += runtime.suppressValue((lineno = 8, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/about/film.jpg"])), env.opts.autoescape);
t_5 += "\" alt=\"\">\n            </div>\n            <p class=\"AboutPress-text\">The film can also be played and embedded via Vimeo link<br><br><a href=\"https://vimeo.com/467342474\" target=\"_blank\" class=\"AboutPress-link\">→ https://vimeo.com/467342474</a></p>\n        </div>\n        <div class=\"AboutPress-buttons\">\n            <div class=\"AboutPress-buttonsItemWrapper\">\n                <a href=\"mailto: danil@myshli.com\" class=\"AboutPress-buttonsItem\">\n                    <span>Contact</span>\n                </a>\n            </div>\n            <div class=\"AboutPress-buttonsItemWrapper\">\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 19, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/about/HIRES.zip"])), env.opts.autoescape);
t_5 += "\" download class=\"AboutPress-buttonsItem\">\n                    <span>Hi res visuals</span>\n                </a>\n            </div>\n        </div>\n    </div> \n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("AboutPress");
context.setVariable("AboutPress", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/AboutPress/AboutPress.jinja"] , dependencies)