var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupResources/MemoriesPopupResources.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupResources/MemoriesPopupResources.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["popup"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("popup", Object.prototype.hasOwnProperty.call(kwargs, "popup") ? kwargs["popup"] : {});var t_5 = "";t_5 += "\n    <div class=\"MemoriesPopup-resources Cursor-redZone isAnimated\">\n        <div class=\"MemoriesPopup-resourcesHeader\">\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"resourcesTitle")) {
t_5 += "\n                <div class=\"MemoriesPopup-resourcesTitle\">Memory:";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"resourcesTitle"), env.opts.autoescape);
t_5 += "</div>\n                <div class=\"MemoriesPopup-resourcesCaption\">Logged Resources</div>\n            ";
;
}
else {
t_5 += "\n                <div class=\"MemoriesPopup-resourcesCaption\">Thank you!</div>\n            ";
;
}
t_5 += "\n        </div>\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"resources")) {
t_5 += "\n            <ul class=\"MemoriesPopup-resourcesList\">\n                ";
frame = frame.push();
var t_8 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"resources");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                    <li class=\"MemoriesPopup-resourcesListItem\">\n                        <div class=\"MemoriesPopup-resourcesListItemLine\"></div>\n                        <div class=\"MemoriesPopup-resourcesListItemTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
t_5 += ":</div>\n                        <div class=\"MemoriesPopup-resourcesListItemValue\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"value"), env.opts.autoescape);
t_5 += "</div>\n                    </li>\n                ";
;
}
}
frame = frame.pop();
t_5 += "\n            </ul>\n        ";
;
}
t_5 += "\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"resources")) < 1) {
t_5 += "\n            <p class=\"MemoriesPopup-resourcesText\">This was quite the enthralling journey. When I compare where I am today with where I was at the beginning of this project, it’s striking how approaching challenges that seem, at first, insurmountable, has become much easier. And I got the opportunity to meet and work with a huge number of incredibly talented people. After viewing every shot probably close to 500 times, I can’t tell anymore what the result actually turned out to be, but the process of getting there was undoubtedly worth it.<br><br>Thank you to you all!</p>\n        ";
;
}
t_5 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("MemoriesPopupResources");
context.setVariable("MemoriesPopupResources", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupResources/MemoriesPopupResources.jinja"] , dependencies)