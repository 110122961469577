var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupNote/MemoriesPopupNote.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupNote/MemoriesPopupNote.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["block"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("block", Object.prototype.hasOwnProperty.call(kwargs, "block") ? kwargs["block"] : {});var t_5 = "";t_5 += "\n    <div class=\"MemoriesPopup-note isAnimated\">\n        <div class=\"MemoriesPopup-noteQuestion\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"question"), env.opts.autoescape);
t_5 += "</div>\n        <div class=\"MemoriesPopup-noteAnswer\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"answer"), env.opts.autoescape);
t_5 += "</div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("MemoriesPopupNote");
context.setVariable("MemoriesPopupNote", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupNote/MemoriesPopupNote.jinja"] , dependencies)