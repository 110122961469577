var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/IndexIntro/IndexIntro.jinja"] = require( "front/components/IndexIntro/IndexIntro.jinja" );
dependencies["front/components/CommonVideoBackground/CommonVideoBackground.jinja"] = require( "front/components/CommonVideoBackground/CommonVideoBackground.jinja" );
dependencies["front/components/CommonVideoPopup/CommonVideoPopup.jinja"] = require( "front/components/CommonVideoPopup/CommonVideoPopup.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/Index/IndexSection.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/Index/IndexSection.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/Index/IndexSection.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n\n";
env.getTemplate("front/components/IndexIntro/IndexIntro.jinja", false, "assets/app/front/pages/Index/IndexSection.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "IndexIntro")) {
var t_10 = t_7.IndexIntro;
} else {
cb(new Error("cannot import 'IndexIntro'")); return;
}
context.setVariable("IndexIntro", t_10);
output += "\n";
env.getTemplate("front/components/CommonVideoBackground/CommonVideoBackground.jinja", false, "assets/app/front/pages/Index/IndexSection.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "CommonVideoBackground")) {
var t_14 = t_11.CommonVideoBackground;
} else {
cb(new Error("cannot import 'CommonVideoBackground'")); return;
}
context.setVariable("CommonVideoBackground", t_14);
output += "\n";
env.getTemplate("front/components/CommonVideoPopup/CommonVideoPopup.jinja", false, "assets/app/front/pages/Index/IndexSection.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "CommonVideoPopup")) {
var t_18 = t_15.CommonVideoPopup;
} else {
cb(new Error("cannot import 'CommonVideoPopup'")); return;
}
context.setVariable("CommonVideoPopup", t_18);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_22,t_21) {
if(t_22) { cb(t_22); return; }
output += t_21;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content_class"))(env, context, frame, runtime, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
output += t_23;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_26,t_25) {
if(t_26) { cb(t_26); return; }
output += t_25;
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " Blindsight ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 12, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/share/share-default.jpg"])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 15, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content_class(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 22;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"IndexSection PageSection\">\n        <div class=\"IndexSection-wrapper NeedFixOnPopup\">\n            ";
output += runtime.suppressValue((lineno = 25, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "IndexIntro"), "IndexIntro", context, [])), env.opts.autoescape);
output += "\n            ";
output += runtime.suppressValue((lineno = 26, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonVideoBackground"), "CommonVideoBackground", context, [runtime.contextOrFrameLookup(context, frame, "index")])), env.opts.autoescape);
output += "\n            ";
output += runtime.suppressValue((lineno = 27, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonVideoPopup"), "CommonVideoPopup", context, [])), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"Section-content\">\n            ";
context.getBlock("section_content")(env, context, frame, runtime, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
output += t_27;
output += "\n        </div>\n    </div>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_section_content(env, context, frame, runtime, cb) {
var lineno = 30;
var colno = 15;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_content_class: b_content_class,
b_content: b_content,
b_section_content: b_section_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/Index/IndexSection.jinja"] , dependencies)