var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonVideoPlayer/CommonVideoPlayer.jinja"] = require( "front/components/CommonVideoPlayer/CommonVideoPlayer.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CommonVideoPopup/CommonVideoPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/CommonVideoPopup/CommonVideoPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonVideoPlayer/CommonVideoPlayer.jinja", false, "assets/app/front/components/CommonVideoPopup/CommonVideoPopup.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonVideoPlayer")) {
var t_7 = t_4.CommonVideoPlayer;
} else {
cb(new Error("cannot import 'CommonVideoPlayer'")); return;
}
context.setVariable("CommonVideoPlayer", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_9 = "";t_9 += "\n    <div class=\"CommonVideoPopup\">\n        <a href=\"\" class=\"CommonVideoPopup-close\">\n            ";
t_9 += runtime.suppressValue((lineno = 7, colno = 36, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-close",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
t_9 += "\n        </a>\n        ";
t_9 += "\n        <div class=\"CommonVideoPopup-inner\">\n            <div class=\"plyr__video-embed VimeoPlayer\">\n                <iframe src=\"https://player.vimeo.com/video/467342474?title=0&byline=0&portrait=0\" width=\"100%\" height=\"100%\" allowfullscreen allowtransparency frameborder=\"0\"></iframe>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("CommonVideoPopup");
context.setVariable("CommonVideoPopup", macro_t_8);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CommonVideoPopup/CommonVideoPopup.jinja"] , dependencies)