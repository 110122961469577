var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/AboutNovel/AboutNovel.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/AboutNovel/AboutNovel.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += "\n    <div class=\"AboutNovel isAnimated\">\n        <div class=\"AboutNovel-inner\">\n            <div class=\"AboutNovel-title\">Novel</div>\n            <p class=\"AboutNovel-text\">Blindsight’s author, Peter Watts, is a Canadian science fiction writer, working primarily in the hard science fiction genre. In 1991, he earned a Ph.D in Zoology and Resource Ecology from the University of British Columbia in Vancouver, British Columbia. He went on to hold several academic research and teaching positions, and worked as a marine mammal biologist. He began publishing fiction around the time he finished graduate school.<br><br>Blindsight was published in 2006. It garnered nominations for a Hugo Award for best novel, a John W. Campbell Memorial Award for best science fiction novel, and a Locus Award for best science fiction novel, and won the Seiun Award for best translated novel. The book follows a crew of astronauts sent out to investigate a trans-neptunian Kuiper Belt comet dubbed 'Burns-Caulfield' that has been found to be transmitting an unidentified radio signal to an as-yet unknown destination elsewhere in the solar system, followed by their subsequent first contact.</p>\n        </div>\n        <div class=\"AboutNovel-buttons\">\n            <div class=\"AboutNovel-buttonsItemWrapper\">\n                <a href=\"https://www.rifters.com/real/Blindsight.htm\" target=\"_blank\" class=\"AboutNovel-buttonsItem\">\n                    <span>Read Book Online</span>\n                </a>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("AboutNovel");
context.setVariable("AboutNovel", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/AboutNovel/AboutNovel.jinja"] , dependencies)