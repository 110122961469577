var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/components/CommonVideoBackground/CommonVideoBackground.jinja"] = require( "front/components/CommonVideoBackground/CommonVideoBackground.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/NotFound/NotFound.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/NotFound/NotFound.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/components/CommonVideoBackground/CommonVideoBackground.jinja", false, "assets/app/front/pages/NotFound/NotFound.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonVideoBackground")) {
var t_7 = t_4.CommonVideoBackground;
} else {
cb(new Error("cannot import 'CommonVideoBackground'")); return;
}
context.setVariable("CommonVideoBackground", t_7);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
output += t_8;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_11,t_10) {
if(t_11) { cb(t_11); return; }
output += t_10;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
output += t_12;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
output += t_14;
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 4;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " Not Found ";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 9, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/share/share-default.jpg"])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 12, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " Page404";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 19;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"NotFound\">\n        <div class=\"NotFound-inner\">\n            <div class=\"NotFound-nav\">\n                <a href=\"/memories/\" class=\"NotFound-navItem\">Corrupt Memories</a>\n                <a href=\"/\" class=\"NotFound-navItem\">Restart Application →</a>\n            </div>\n            <div class=\"NotFound-title\">404</div>\n        </div>\n        ";
output += runtime.suppressValue((lineno = 28, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonVideoBackground"), "CommonVideoBackground", context, [runtime.contextOrFrameLookup(context, frame, "error")])), env.opts.autoescape);
output += "\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_body_class: b_body_class,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/NotFound/NotFound.jinja"] , dependencies)