var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonVideoPlayer/CommonVideoPlayer.jinja"] = require( "front/components/CommonVideoPlayer/CommonVideoPlayer.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonVideoPlayer/CommonVideoPlayer.jinja", false, "assets/app/front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonVideoPlayer")) {
var t_7 = t_4.CommonVideoPlayer;
} else {
cb(new Error("cannot import 'CommonVideoPlayer'")); return;
}
context.setVariable("CommonVideoPlayer", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["block"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("block", Object.prototype.hasOwnProperty.call(kwargs, "block") ? kwargs["block"] : {});var t_9 = "";t_9 += "\n    <div class=\"MemoriesPopup-video isAnimated";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"largeSize") == "true") {
t_9 += " isLargeSize";
;
}
t_9 += "\"\n        style=\"background: \n                radial-gradient(transparent 3px, #";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"background"), env.opts.autoescape);
t_9 += " 3px),\n                radial-gradient(transparent 3px, #";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"background"), env.opts.autoescape);
t_9 += " 3px),\n                linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px) 0 -.5px,\n                linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px) -.5px 0;\n            background-color: #";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"background"), env.opts.autoescape);
t_9 += ";\">\n        <div class=\"MemoriesPopup-videoCover\">\n            ";
t_9 += runtime.suppressValue((lineno = 13, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonVideoPlayer"), "CommonVideoPlayer", context, [runtime.contextOrFrameLookup(context, frame, "block")])), env.opts.autoescape);
t_9 += "\n        </div>\n        <div class=\"MemoriesPopup-videoFooter\">\n            <div class=\"MemoriesPopup-videoTitle\">";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"title"), env.opts.autoescape);
t_9 += "</div>\n            <div class=\"MemoriesPopup-videoFooterInner\">\n                <div class=\"MemoriesPopup-videoCaption\">";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"caption"), env.opts.autoescape);
t_9 += "</div>\n                <div class=\"MemoriesPopup-videoFile\">File→<br>";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"file"), env.opts.autoescape);
t_9 += "</div>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("MemoriesPopupVideo");
context.setVariable("MemoriesPopupVideo", macro_t_8);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja"] , dependencies)