var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/CommonVideoPlayer/CommonVideoPlayer.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/CommonVideoPlayer/CommonVideoPlayer.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "mods"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("mods", Object.prototype.hasOwnProperty.call(kwargs, "mods") ? kwargs["mods"] : []);var t_5 = "";t_5 += "\n    <div class=\"CommonVideoPlayer";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "mods");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("mod", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += " ";
t_5 += runtime.suppressValue(t_9, env.opts.autoescape);
;
}
}
frame = frame.pop();
t_5 += "\">\n        <div class=\"CommonVideoPlayer-inner Cursor-play\">\n            <video";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"largeSize") == "true") {
t_5 += " poster=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"videoPoster"), env.opts.autoescape);
t_5 += "\"";
;
}
t_5 += " canplaythrough playsinline class=\"CommonVideoPlayer-cover\">\n                <source";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "data"))) {
t_5 += " src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"video"), env.opts.autoescape);
t_5 += "\"";
;
}
t_5 += " type=\"video/mp4\">\n            </video>\n            <div class=\"CommonVideoPlayer-playback isPlay\">\n                ";
t_5 += "\n            </div>\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"largeSize") != "true") {
t_5 += "\n                <img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"poster"), env.opts.autoescape);
t_5 += "\" class=\"CommonVideoPlayer-image\" alt=\"\">\n                <img src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"posterHover"), env.opts.autoescape);
t_5 += "\" class=\"CommonVideoPlayer-image\" alt=\"\">\n            ";
;
}
t_5 += "\n        </div>\n        <div class=\"CommonVideoPlayer-controls\">\n            <div class=\"CommonVideoPlayer-controlsCurrentTime\">00:00</div>\n            <div class=\"CommonVideoPlayer-controlsTimeline\">\n                <div class=\"CommonVideoPlayer-controlsTimelineProgress\"></div>\n                <div class=\"CommonVideoPlayer-controlsTimelineLine\"></div>\n            </div>\n            <div class=\"CommonVideoPlayer-controlsDuration\">00:00</div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("CommonVideoPlayer");
context.setVariable("CommonVideoPlayer", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/CommonVideoPlayer/CommonVideoPlayer.jinja"] , dependencies)