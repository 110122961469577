var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/MemoriesPopupIntro/MemoriesPopupIntro.jinja"] = require( "front/components/MemoriesPopupIntro/MemoriesPopupIntro.jinja" );
dependencies["front/components/MemoriesPopupChat/MemoriesPopupChat.jinja"] = require( "front/components/MemoriesPopupChat/MemoriesPopupChat.jinja" );
dependencies["front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja"] = require( "front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja" );
dependencies["front/components/MemoriesPopupNote/MemoriesPopupNote.jinja"] = require( "front/components/MemoriesPopupNote/MemoriesPopupNote.jinja" );
dependencies["front/components/MemoriesPopupFilm/MemoriesPopupFilm.jinja"] = require( "front/components/MemoriesPopupFilm/MemoriesPopupFilm.jinja" );
dependencies["front/components/MemoriesPopupWallpaper/MemoriesPopupWallpaper.jinja"] = require( "front/components/MemoriesPopupWallpaper/MemoriesPopupWallpaper.jinja" );
dependencies["front/components/MemoriesPopupResources/MemoriesPopupResources.jinja"] = require( "front/components/MemoriesPopupResources/MemoriesPopupResources.jinja" );
dependencies["front/components/MemoriesPopupNext/MemoriesPopupNext.jinja"] = require( "front/components/MemoriesPopupNext/MemoriesPopupNext.jinja" );
dependencies["front/components/MemoriesPopupLater/MemoriesPopupLater.jinja"] = require( "front/components/MemoriesPopupLater/MemoriesPopupLater.jinja" );
dependencies["front/components/MemoriesPopupModel/MemoriesPopupModel.jinja"] = require( "front/components/MemoriesPopupModel/MemoriesPopupModel.jinja" );
dependencies["front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja"] = require( "front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja" );
dependencies["front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja"] = require( "front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja" );
dependencies["front/components/MemoriesPopupAcknowledgements/MemoriesPopupAcknowledgements.jinja"] = require( "front/components/MemoriesPopupAcknowledgements/MemoriesPopupAcknowledgements.jinja" );
dependencies["front/components/MemoriesPopupInfo/MemoriesPopupInfo.jinja"] = require( "front/components/MemoriesPopupInfo/MemoriesPopupInfo.jinja" );
dependencies["front/components/MemoriesPopupCredits/MemoriesPopupCredits.jinja"] = require( "front/components/MemoriesPopupCredits/MemoriesPopupCredits.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/MemoriesPopupIntro/MemoriesPopupIntro.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "MemoriesPopupIntro")) {
var t_7 = t_4.MemoriesPopupIntro;
} else {
cb(new Error("cannot import 'MemoriesPopupIntro'")); return;
}
context.setVariable("MemoriesPopupIntro", t_7);
output += "\n";
env.getTemplate("front/components/MemoriesPopupChat/MemoriesPopupChat.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "MemoriesPopupChat")) {
var t_11 = t_8.MemoriesPopupChat;
} else {
cb(new Error("cannot import 'MemoriesPopupChat'")); return;
}
context.setVariable("MemoriesPopupChat", t_11);
output += "\n";
env.getTemplate("front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "MemoriesPopupGallery")) {
var t_15 = t_12.MemoriesPopupGallery;
} else {
cb(new Error("cannot import 'MemoriesPopupGallery'")); return;
}
context.setVariable("MemoriesPopupGallery", t_15);
output += "\n";
env.getTemplate("front/components/MemoriesPopupNote/MemoriesPopupNote.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "MemoriesPopupNote")) {
var t_19 = t_16.MemoriesPopupNote;
} else {
cb(new Error("cannot import 'MemoriesPopupNote'")); return;
}
context.setVariable("MemoriesPopupNote", t_19);
output += "\n";
env.getTemplate("front/components/MemoriesPopupFilm/MemoriesPopupFilm.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "MemoriesPopupFilm")) {
var t_23 = t_20.MemoriesPopupFilm;
} else {
cb(new Error("cannot import 'MemoriesPopupFilm'")); return;
}
context.setVariable("MemoriesPopupFilm", t_23);
output += "\n";
env.getTemplate("front/components/MemoriesPopupWallpaper/MemoriesPopupWallpaper.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "MemoriesPopupWallpaper")) {
var t_27 = t_24.MemoriesPopupWallpaper;
} else {
cb(new Error("cannot import 'MemoriesPopupWallpaper'")); return;
}
context.setVariable("MemoriesPopupWallpaper", t_27);
output += "\n";
env.getTemplate("front/components/MemoriesPopupResources/MemoriesPopupResources.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "MemoriesPopupResources")) {
var t_31 = t_28.MemoriesPopupResources;
} else {
cb(new Error("cannot import 'MemoriesPopupResources'")); return;
}
context.setVariable("MemoriesPopupResources", t_31);
output += "\n";
env.getTemplate("front/components/MemoriesPopupNext/MemoriesPopupNext.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
t_32.getExported(function(t_34,t_32) {
if(t_34) { cb(t_34); return; }
if(Object.prototype.hasOwnProperty.call(t_32, "MemoriesPopupNext")) {
var t_35 = t_32.MemoriesPopupNext;
} else {
cb(new Error("cannot import 'MemoriesPopupNext'")); return;
}
context.setVariable("MemoriesPopupNext", t_35);
output += "\n";
env.getTemplate("front/components/MemoriesPopupLater/MemoriesPopupLater.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
t_36.getExported(function(t_38,t_36) {
if(t_38) { cb(t_38); return; }
if(Object.prototype.hasOwnProperty.call(t_36, "MemoriesPopupLater")) {
var t_39 = t_36.MemoriesPopupLater;
} else {
cb(new Error("cannot import 'MemoriesPopupLater'")); return;
}
context.setVariable("MemoriesPopupLater", t_39);
output += "\n";
env.getTemplate("front/components/MemoriesPopupModel/MemoriesPopupModel.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
t_40.getExported(function(t_42,t_40) {
if(t_42) { cb(t_42); return; }
if(Object.prototype.hasOwnProperty.call(t_40, "MemoriesPopupModel")) {
var t_43 = t_40.MemoriesPopupModel;
} else {
cb(new Error("cannot import 'MemoriesPopupModel'")); return;
}
context.setVariable("MemoriesPopupModel", t_43);
output += "\n";
env.getTemplate("front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_45,t_44) {
if(t_45) { cb(t_45); return; }
t_44.getExported(function(t_46,t_44) {
if(t_46) { cb(t_46); return; }
if(Object.prototype.hasOwnProperty.call(t_44, "MemoriesPopupExploration")) {
var t_47 = t_44.MemoriesPopupExploration;
} else {
cb(new Error("cannot import 'MemoriesPopupExploration'")); return;
}
context.setVariable("MemoriesPopupExploration", t_47);
output += "\n";
env.getTemplate("front/components/MemoriesPopupVideo/MemoriesPopupVideo.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_49,t_48) {
if(t_49) { cb(t_49); return; }
t_48.getExported(function(t_50,t_48) {
if(t_50) { cb(t_50); return; }
if(Object.prototype.hasOwnProperty.call(t_48, "MemoriesPopupVideo")) {
var t_51 = t_48.MemoriesPopupVideo;
} else {
cb(new Error("cannot import 'MemoriesPopupVideo'")); return;
}
context.setVariable("MemoriesPopupVideo", t_51);
output += "\n";
env.getTemplate("front/components/MemoriesPopupAcknowledgements/MemoriesPopupAcknowledgements.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_53,t_52) {
if(t_53) { cb(t_53); return; }
t_52.getExported(function(t_54,t_52) {
if(t_54) { cb(t_54); return; }
if(Object.prototype.hasOwnProperty.call(t_52, "MemoriesPopupAcknowledgements")) {
var t_55 = t_52.MemoriesPopupAcknowledgements;
} else {
cb(new Error("cannot import 'MemoriesPopupAcknowledgements'")); return;
}
context.setVariable("MemoriesPopupAcknowledgements", t_55);
output += "\n";
env.getTemplate("front/components/MemoriesPopupInfo/MemoriesPopupInfo.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_57,t_56) {
if(t_57) { cb(t_57); return; }
t_56.getExported(function(t_58,t_56) {
if(t_58) { cb(t_58); return; }
if(Object.prototype.hasOwnProperty.call(t_56, "MemoriesPopupInfo")) {
var t_59 = t_56.MemoriesPopupInfo;
} else {
cb(new Error("cannot import 'MemoriesPopupInfo'")); return;
}
context.setVariable("MemoriesPopupInfo", t_59);
output += "\n";
env.getTemplate("front/components/MemoriesPopupCredits/MemoriesPopupCredits.jinja", false, "assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja", false, function(t_61,t_60) {
if(t_61) { cb(t_61); return; }
t_60.getExported(function(t_62,t_60) {
if(t_62) { cb(t_62); return; }
if(Object.prototype.hasOwnProperty.call(t_60, "MemoriesPopupCredits")) {
var t_63 = t_60.MemoriesPopupCredits;
} else {
cb(new Error("cannot import 'MemoriesPopupCredits'")); return;
}
context.setVariable("MemoriesPopupCredits", t_63);
output += "\n\n<div class=\"MemoriesPopup\">\n    <div class=\"MemoriesPopup-overlay\"></div>\n    <div class=\"MemoriesPopup-inner\">\n        <a href=\"\" class=\"MemoriesPopup-close\">\n            ";
output += runtime.suppressValue((lineno = 22, colno = 36, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-close",runtime.makeKeywordArgs({"caller": (function (){var macro_t_64 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_65 = "";;
frame = frame.pop();
return new runtime.SafeString(t_65);
});
return macro_t_64;})()})])), env.opts.autoescape);
output += "\n        </a>\n        <div class=\"HorizontalPage\">\n            <div class=\"MemoriesPopup-content\">\n                ";
output += runtime.suppressValue((lineno = 26, colno = 37, runtime.callWrap(t_7, "MemoriesPopupIntro", context, [runtime.contextOrFrameLookup(context, frame, "popup")])), env.opts.autoescape);
output += "\n                ";
frame = frame.push();
var t_68 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"blocks");
if(t_68) {t_68 = runtime.fromIterator(t_68);
var t_67 = t_68.length;
for(var t_66=0; t_66 < t_68.length; t_66++) {
var t_69 = t_68[t_66];
frame.set("block", t_69);
frame.set("loop.index", t_66 + 1);
frame.set("loop.index0", t_66);
frame.set("loop.revindex", t_67 - t_66);
frame.set("loop.revindex0", t_67 - t_66 - 1);
frame.set("loop.first", t_66 === 0);
frame.set("loop.last", t_66 === t_67 - 1);
frame.set("loop.length", t_67);
output += "\n                    ";
if(runtime.memberLookup((t_69),"type") == "chat") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 29, colno = 44, runtime.callWrap(t_11, "MemoriesPopupChat", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "gallery") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 31, colno = 47, runtime.callWrap(t_15, "MemoriesPopupGallery", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "note") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 33, colno = 44, runtime.callWrap(t_19, "MemoriesPopupNote", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "film") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 35, colno = 44, runtime.callWrap(t_23, "MemoriesPopupFilm", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "later") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 37, colno = 45, runtime.callWrap(t_39, "MemoriesPopupLater", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "model") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 39, colno = 45, runtime.callWrap(t_43, "MemoriesPopupModel", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "exploration") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 41, colno = 51, runtime.callWrap(t_47, "MemoriesPopupExploration", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "video") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 43, colno = 45, runtime.callWrap(t_51, "MemoriesPopupVideo", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "acknowledgements") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 45, colno = 56, runtime.callWrap(t_55, "MemoriesPopupAcknowledgements", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "info") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 47, colno = 44, runtime.callWrap(t_59, "MemoriesPopupInfo", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
if(runtime.memberLookup((t_69),"type") == "credits") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 49, colno = 47, runtime.callWrap(t_63, "MemoriesPopupCredits", context, [t_69])), env.opts.autoescape);
output += "\n                    ";
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
;
}
output += "\n                ";
;
}
}
frame = frame.pop();
output += "\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "popup")),"wallpaper") == "true") {
output += "\n                    ";
output += runtime.suppressValue((lineno = 53, colno = 45, runtime.callWrap(t_27, "MemoriesPopupWallpaper", context, [runtime.contextOrFrameLookup(context, frame, "popup")])), env.opts.autoescape);
output += "\n                ";
;
}
output += "\n                ";
output += runtime.suppressValue((lineno = 55, colno = 41, runtime.callWrap(t_31, "MemoriesPopupResources", context, [runtime.contextOrFrameLookup(context, frame, "popup")])), env.opts.autoescape);
output += "\n                ";
output += runtime.suppressValue((lineno = 56, colno = 36, runtime.callWrap(t_35, "MemoriesPopupNext", context, [runtime.contextOrFrameLookup(context, frame, "popup")])), env.opts.autoescape);
output += "\n                <div class=\"MemoriesPopup-end\"></div>\n            </div>\n        </div>\n        <div class=\"MemoriesPopup-hintWrapper\">\n            <div class=\"MemoriesPopup-hint\">\n                ";
output += runtime.suppressValue((lineno = 62, colno = 40, runtime.callWrap(runtime.memberLookup((t_1),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_70 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_71 = "";;
frame = frame.pop();
return new runtime.SafeString(t_71);
});
return macro_t_70;})()})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/MemoriesPopup/MemoriesPopup.jinja"] , dependencies)