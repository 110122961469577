var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["block"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("block", Object.prototype.hasOwnProperty.call(kwargs, "block") ? kwargs["block"] : {});var t_5 = "";t_5 += "\n    <div class=\"MemoriesPopup-exploration isAnimated\">\n        ";
frame = frame.push();
var t_8 = (lineno = 4, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [1,75]));
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("i", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n            ";
if(t_9 < 10) {
t_5 += "\n                ";
t_9 = env.getFilter("string").call(context, t_9);
frame.set("i", t_9, true);
if(frame.topLevel) {
context.setVariable("i", t_9);
}
if(frame.topLevel) {
context.addExport("i", t_9);
}
t_5 += "\n            ";
;
}
t_5 += "\n            <div class=\"MemoriesPopup-explorationImage";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"last")) {
t_5 += " isShow";
;
}
t_5 += "\" data-src=\"";
t_5 += runtime.suppressValue((lineno = 8, colno = 111, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/popups/5/sequence/solar-" + env.getFilter("string").call(context, t_9) + ".jpg"])), env.opts.autoescape);
t_5 += "\"></div>\n        ";
;
}
}
frame = frame.pop();
t_5 += "\n        <div class=\"MemoriesPopup-explorationFooter\">\n            <div class=\"MemoriesPopup-explorationTitle\">Exploration</div>\n            <div class=\"MemoriesPopup-explorationFooterInner\">\n                <div class=\"MemoriesPopup-explorationCaption\">Render</div>\n                <div class=\"MemoriesPopup-explorationFile\">File→<br>Ui_solar_01.mp4</div>\n            </div>\n        </div>\n        <div class=\"MemoriesPopup-explorationBackground\"></div>\n        <div class=\"MemoriesPopup-explorationLoader\">\n            <div class=\"MemoriesPopup-explorationLoaderSpinner\">\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n                <span class=\"MemoriesPopup-explorationLoaderSpinnerLine\"></span>\n            </div>\n            <div class=\"MemoriesPopup-explorationLoaderInner\">\n                <div class=\"MemoriesPopup-explorationLoaderTitle\">Sequence loading</div>\n                <div class=\"MemoriesPopup-explorationLoaderPercentage\"><span>0</span>/74</div>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("MemoriesPopupExploration");
context.setVariable("MemoriesPopupExploration", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupExploration/MemoriesPopupExploration.jinja"] , dependencies)