var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/AboutVideo/AboutVideo.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/AboutVideo/AboutVideo.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";t_5 += "\n    <div class=\"AboutVideo isAnimated\">\n        <div class=\"AboutVideo-inner\">\n            <a href=\"\" class=\"AboutVideo-playIcon Cursor-play Button-cta\">\n                <svg width=\"100%\" height=\"100%\" viewBox=\"0 0 220 220\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n                    <g fill=\"none\" fill-rule=\"evenodd\">\n                        <g transform=\"translate(-1500.000000, -440.000000)\" stroke=\"#ffffff\" stroke-width=\"4\">\n                            <g transform=\"translate(171.000000, 81.000000)\">\n                                <g style=\"overflow: hidden\" transform=\"translate(1329.000000, 359.000000)\">\n                                    <circle cx=\"110\" cy=\"110\" r=\"108\"></circle>\n                                    <path d=\"M82,73.236068 L82,146.763932 L155.527864,110 L82,73.236068 Z\"></path>\n                                </g>\n                            </g>\n                        </g>\n                    </g>\n                </svg>\n            </a>\n            <div class=\"AboutVideo-buttonWrapper\">\n                <a href=\"\" class=\"AboutVideo-button Button-cta\">\n                    <span>Rewatch Film</span>\n                </a>\n            </div>\n        </div>\n        <video preload=\"none\" loop muted canplaythrough playsinline>\n            <source src=\"";
t_5 += runtime.suppressValue((lineno = 26, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["videos/home.mp4"])), env.opts.autoescape);
t_5 += "\" type=\"video/mp4\">\n        </video>\n        <img src=\"";
t_5 += runtime.suppressValue((lineno = 28, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/about/rewatch.jpg"])), env.opts.autoescape);
t_5 += "\" alt=\"\">\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("AboutVideo");
context.setVariable("AboutVideo", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/AboutVideo/AboutVideo.jinja"] , dependencies)