var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonAudioPlayer/CommonAudioPlayer.jinja"] = require( "front/components/CommonAudioPlayer/CommonAudioPlayer.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupChat/MemoriesPopupChat.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupChat/MemoriesPopupChat.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
env.getTemplate("front/components/CommonAudioPlayer/CommonAudioPlayer.jinja", false, "assets/app/front/components/MemoriesPopupChat/MemoriesPopupChat.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "CommonAudioPlayer")) {
var t_7 = t_4.CommonAudioPlayer;
} else {
cb(new Error("cannot import 'CommonAudioPlayer'")); return;
}
context.setVariable("CommonAudioPlayer", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["block"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("block", Object.prototype.hasOwnProperty.call(kwargs, "block") ? kwargs["block"] : {});var t_9 = "";t_9 += "\n    <div class=\"MemoriesPopup-chat\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"largeSize") == "true") {
t_9 += " isLargeSize\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"mediumSize") == "true") {
t_9 += " isMediumSize\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"smallSize") == "true") {
t_9 += " isSmallSize";
;
}
;
}
;
}
t_9 += " isAnimated\">\n        ";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"chat");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("item", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_9 += "\n            <div class=\"MemoriesPopup-chatItem\n                ";
if(runtime.memberLookup((t_13),"theme") == "red") {
t_9 += " isRedTheme Cursor-redZone\n                ";
;
}
else {
if(runtime.memberLookup((t_13),"theme") == "gray") {
t_9 += " isGrayTheme\n                ";
;
}
else {
if(runtime.memberLookup((t_13),"theme") == "white") {
t_9 += " isWhiteTheme";
;
}
;
}
;
}
t_9 += "\">\n                <div class=\"MemoriesPopup-chatItemHeader\">\n                    <div class=\"MemoriesPopup-chatItemNumber\">Message: ";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"number"), env.opts.autoescape);
t_9 += "</div>\n                    <div class=\"MemoriesPopup-chatItemDate\">";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"date"), env.opts.autoescape);
t_9 += "</div>\n                </div>\n                <div class=\"MemoriesPopup-chatItemInner\">\n                    <p class=\"MemoriesPopup-chatItemText\">↪︎";
t_9 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((t_13),"text")), env.opts.autoescape);
t_9 += "</p>\n                    ";
if(runtime.memberLookup((t_13),"audio")) {
t_9 += "\n                        ";
t_9 += runtime.suppressValue((lineno = 21, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonAudioPlayer"), "CommonAudioPlayer", context, [t_13])), env.opts.autoescape);
t_9 += "\n                    ";
;
}
t_9 += "\n                    ";
if(runtime.memberLookup((t_13),"image")) {
t_9 += "\n                        <div class=\"MemoriesPopup-chatItemImage\n                            ";
if(runtime.memberLookup((t_13),"zoom") == "true") {
t_9 += " MemoriesPopup-chatItemImageZoomOpen Cursor-zoom";
;
}
t_9 += "\n                            ";
if(runtime.memberLookup((t_13),"heightLimit") == "true") {
t_9 += " isHeightLimit";
;
}
t_9 += "\">\n                            <img src=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"image"), env.opts.autoescape);
t_9 += "\" alt=\"\">\n                        </div>\n                        <div class=\"MemoriesPopup-chatItemImageZoom\">\n                            <div class=\"MemoriesPopup-chatItemImageZoomClose Cursor-close\">\n                                ";
t_9 += runtime.suppressValue((lineno = 31, colno = 56, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-close",runtime.makeKeywordArgs({"caller": (function (){var macro_t_14 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_15 = "";;
frame = frame.pop();
return new runtime.SafeString(t_15);
});
return macro_t_14;})()})])), env.opts.autoescape);
t_9 += "\n                            </div>\n                            <img data-src=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"imageZoom"), env.opts.autoescape);
t_9 += "\" alt=\"\">\n                        </div>\n                    ";
;
}
t_9 += "\n                    ";
if(runtime.memberLookup((t_13),"reference") == "true") {
t_9 += "\n                        <a href=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"referenceUrl"), env.opts.autoescape);
t_9 += "\" target=\"_blank\" class=\"MemoriesPopup-chatItemReference\">\n                            ";
if(runtime.memberLookup((t_13),"referenceImage")) {
t_9 += "\n                                <div class=\"MemoriesPopup-chatItemReferenceImage\">\n                                    <img src=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"referenceImage"), env.opts.autoescape);
t_9 += "\" alt=\"\">\n                                </div>\n                            ";
;
}
t_9 += "\n                            <div class=\"MemoriesPopup-chatItemReferenceInner\">\n                                <div class=\"MemoriesPopup-chatItemReferenceTitle\">→";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"referenceTitle"), env.opts.autoescape);
t_9 += "</div>\n                                <div class=\"MemoriesPopup-chatItemReferenceText\">";
t_9 += runtime.suppressValue(runtime.memberLookup((t_13),"referenceUrl"), env.opts.autoescape);
t_9 += "</div>\n                            </div>\n                        </a>\n                    ";
;
}
t_9 += "\n                </div>\n            </div>\n        ";
;
}
}
frame = frame.pop();
t_9 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("MemoriesPopupChat");
context.setVariable("MemoriesPopupChat", macro_t_8);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupChat/MemoriesPopupChat.jinja"] , dependencies)