var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesNav/MemoriesNav.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesNav/MemoriesNav.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <div class=\"MemoriesNav isSpiral\">\n        <div class=\"MemoriesNav-inner\">\n            ";
frame = frame.push();
var t_8 = env.getFilter("reverse").call(context, env.getFilter("first").call(context, env.getFilter("batch").call(context, env.getFilter("reverse").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"nav")),4)));
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                <div class=\"MemoriesNav-itemWrapper isCloned\">\n                    <a href=\"";
t_5 += runtime.suppressValue((lineno = 7, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:memories-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_9),"slug")]})])), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-item u-Route\">\n                        <div class=\"MemoriesNav-itemInner\">\n                            <div class=\"MemoriesNav-itemVideo\">\n                                <video preload=\"auto\" loop muted canplaythrough playsinline src=\"\" data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"video"), env.opts.autoescape);
t_5 += "\"></video>\n                                <div data-lazy-load data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"image"), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-itemImage\"></div>\n                            </div>\n                            <div class=\"MemoriesNav-itemContent\">\n                                <div class=\"MemoriesNav-itemCaption\">Memory:";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"slug"), env.opts.autoescape);
t_5 += "</div>\n                                <div class=\"MemoriesNav-itemFooter\">\n                                    <div class=\"MemoriesNav-itemHint\">Explore →</div>\n                                    <div class=\"MemoriesNav-itemTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"title"), env.opts.autoescape);
t_5 += "</div>\n                                </div>\n                            </div>\n                            <div class=\"MemoriesNav-itemOverlay\"></div>\n                        </div>\n                    </a>\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n            ";
frame = frame.push();
var t_12 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"nav");
if(t_12) {t_12 = runtime.fromIterator(t_12);
var t_11 = t_12.length;
for(var t_10=0; t_10 < t_12.length; t_10++) {
var t_13 = t_12[t_10];
frame.set("item", t_13);
frame.set("loop.index", t_10 + 1);
frame.set("loop.index0", t_10);
frame.set("loop.revindex", t_11 - t_10);
frame.set("loop.revindex0", t_11 - t_10 - 1);
frame.set("loop.first", t_10 === 0);
frame.set("loop.last", t_10 === t_11 - 1);
frame.set("loop.length", t_11);
t_5 += "\n                <div class=\"MemoriesNav-itemWrapper\">\n                    <a href=\"";
t_5 += runtime.suppressValue((lineno = 27, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:memories-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_13),"slug")]})])), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-item u-Route\">\n                        <div class=\"MemoriesNav-itemInner\">\n                            <div class=\"MemoriesNav-itemVideo\">\n                                <video preload=\"auto\" loop muted canplaythrough playsinline src=\"\" data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_13),"video"), env.opts.autoescape);
t_5 += "\"></video>\n                                <div data-lazy-load data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_13),"image"), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-itemImage\"></div>\n                            </div>\n                            <div class=\"MemoriesNav-itemContent\">\n                                <div class=\"MemoriesNav-itemCaption\">Memory:";
t_5 += runtime.suppressValue(runtime.memberLookup((t_13),"slug"), env.opts.autoescape);
t_5 += "</div>\n                                <div class=\"MemoriesNav-itemFooter\">\n                                    <div class=\"MemoriesNav-itemHint\">Explore →</div>\n                                    <div class=\"MemoriesNav-itemTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_13),"title"), env.opts.autoescape);
t_5 += "</div>\n                                </div>\n                            </div>\n                            <div class=\"MemoriesNav-itemOverlay\"></div>\n                        </div>\n                    </a>\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n            ";
frame = frame.push();
var t_16 = env.getFilter("first").call(context, env.getFilter("batch").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"nav"),5));
if(t_16) {t_16 = runtime.fromIterator(t_16);
var t_15 = t_16.length;
for(var t_14=0; t_14 < t_16.length; t_14++) {
var t_17 = t_16[t_14];
frame.set("item", t_17);
frame.set("loop.index", t_14 + 1);
frame.set("loop.index0", t_14);
frame.set("loop.revindex", t_15 - t_14);
frame.set("loop.revindex0", t_15 - t_14 - 1);
frame.set("loop.first", t_14 === 0);
frame.set("loop.last", t_14 === t_15 - 1);
frame.set("loop.length", t_15);
t_5 += "\n                <div class=\"MemoriesNav-itemWrapper isCloned\">\n                    <a href=\"";
t_5 += runtime.suppressValue((lineno = 47, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:memories-popup",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_17),"slug")]})])), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-item u-Route\">\n                        <div class=\"MemoriesNav-itemInner\">\n                            <div class=\"MemoriesNav-itemVideo\">\n                                <video preload=\"auto\" loop muted canplaythrough playsinline src=\"\" data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_17),"video"), env.opts.autoescape);
t_5 += "\"></video>\n                                <div data-lazy-load data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_17),"image"), env.opts.autoescape);
t_5 += "\" class=\"MemoriesNav-itemImage\"></div>\n                            </div>\n                            <div class=\"MemoriesNav-itemContent\">\n                                <div class=\"MemoriesNav-itemCaption\">Memory:";
t_5 += runtime.suppressValue(runtime.memberLookup((t_17),"slug"), env.opts.autoescape);
t_5 += "</div>\n                                <div class=\"MemoriesNav-itemFooter\">\n                                    <div class=\"MemoriesNav-itemHint\">Explore →</div>\n                                    <div class=\"MemoriesNav-itemTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((t_17),"title"), env.opts.autoescape);
t_5 += "</div>\n                                </div>\n                            </div>\n                            <div class=\"MemoriesNav-itemOverlay\"></div>\n                        </div>\n                    </a>\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n        <div class=\"MemoriesNav-control\">\n            <div class=\"MemoriesNav-controlPrev\">\n                ";
t_5 += runtime.suppressValue((lineno = 68, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_18 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_19 = "";;
frame = frame.pop();
return new runtime.SafeString(t_19);
});
return macro_t_18;})()})])), env.opts.autoescape);
t_5 += "\n            </div>\n            <div class=\"MemoriesNav-controlNext\">\n                ";
t_5 += runtime.suppressValue((lineno = 71, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_20 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_21 = "";;
frame = frame.pop();
return new runtime.SafeString(t_21);
});
return macro_t_20;})()})])), env.opts.autoescape);
t_5 += "\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("MemoriesNav");
context.setVariable("MemoriesNav", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesNav/MemoriesNav.jinja"] , dependencies)