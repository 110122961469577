var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["block"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("block", Object.prototype.hasOwnProperty.call(kwargs, "block") ? kwargs["block"] : {});var t_5 = "";t_5 += "\n    <div class=\"MemoriesPopup-galleryWrapper\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"largeSize") == "true") {
t_5 += " isLargeSize\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"mediumSize") == "true") {
t_5 += " isMediumSize\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"smallSize") == "true") {
t_5 += " isSmallSize";
;
}
;
}
;
}
t_5 += "\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"blackTheme") == "true") {
t_5 += " isBlackTheme";
;
}
t_5 += "\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"centeredGallery") == "true") {
t_5 += " isCentered";
;
}
t_5 += "\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"videoGallery") == "true") {
t_5 += " isVideoGallery";
;
}
t_5 += " isAnimated\">\n        <div class=\"MemoriesPopup-gallery\"";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery")) > 1) {
t_5 += " data-gallery";
;
}
t_5 += ">\n            ";
var t_6;
t_6 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery");
frame.set("galleryLength", t_6, true);
if(frame.topLevel) {
context.setVariable("galleryLength", t_6);
}
if(frame.topLevel) {
context.addExport("galleryLength", t_6);
}
t_5 += "\n            ";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("item", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
t_5 += "\n                <div class=\"MemoriesPopup-galleryItem\n                ";
if(runtime.memberLookup((t_10),"zoom") == "true") {
t_5 += " MemoriesPopup-galleryZoomOpen Cursor-zoom";
;
}
t_5 += "\n                ";
if(runtime.memberLookup((t_10),"backgroundGrid") == "true") {
t_5 += " isBackgroundGrid";
;
}
t_5 += "\"\n                ";
if(runtime.memberLookup((t_10),"backgroundGrid") == "true") {
t_5 += "\n                    style=\"background: \n                            radial-gradient(transparent 3px, #";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"background"), env.opts.autoescape);
t_5 += " 3px),\n                            radial-gradient(transparent 3px, #";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"background"), env.opts.autoescape);
t_5 += " 3px),\n                            linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px) 0 -.5px,\n                            linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px) -.5px 0;\n                        background-color: #";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"background"), env.opts.autoescape);
t_5 += ";\"\n                ";
;
}
else {
if(runtime.memberLookup((t_10),"backgroundGrid") == "false") {
t_5 += "\n                    style=\"background-color: #";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"background"), env.opts.autoescape);
t_5 += "\"\n                ";
;
}
;
}
t_5 += ">\n                    <div class=\"MemoriesPopup-galleryItemInner";
if(runtime.memberLookup((t_10),"fill") == "cover") {
t_5 += " isTypeCover";
;
}
t_5 += "\">\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"videoGallery") == "true") {
t_5 += "\n                            <video preload=\"none\" autoplay loop muted canplaythrough playsinline>\n                                <source src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"video"), env.opts.autoescape);
t_5 += "\" type=\"video/mp4\">\n                            </video>\n                            <img data-lazy-load data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"image"), env.opts.autoescape);
t_5 += "\" alt=\"\">\n                        ";
;
}
else {
t_5 += "\n                            <img data-lazy-load data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_10),"image"), env.opts.autoescape);
t_5 += "\" alt=\"\">\n                        ";
;
}
t_5 += "\n                    </div>\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"showZoom") == "true" || env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery")) > 1) {
t_5 += "\n            <div class=\"MemoriesPopup-galleryOverlay\"></div>\n        ";
;
}
t_5 += "\n        <div class=\"MemoriesPopup-galleryFooter\">\n            <div class=\"MemoriesPopup-galleryInfo\">\n                <div class=\"MemoriesPopup-galleryTitle\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"title"), env.opts.autoescape);
t_5 += "</div>\n                <div class=\"MemoriesPopup-galleryInfoInner\">\n                    <div class=\"MemoriesPopup-galleryCaption\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"caption"), env.opts.autoescape);
t_5 += "</div>\n                    <div class=\"MemoriesPopup-galleryFile\">File→<br>";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"file"), env.opts.autoescape);
t_5 += "</div>\n                </div>\n            </div>\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery")) > 1) {
t_5 += "\n                <div class=\"MemoriesPopup-galleryNav\">\n                    <a href=\"\" class=\"MemoriesPopup-galleryNavPrev\" data-gallery-prev>\n                        ";
t_5 += runtime.suppressValue((lineno = 53, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_11 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_12 = "";;
frame = frame.pop();
return new runtime.SafeString(t_12);
});
return macro_t_11;})()})])), env.opts.autoescape);
t_5 += "\n                    </a>\n                    <a href=\"\" class=\"MemoriesPopup-galleryNavNext\" data-gallery-next>\n                        ";
t_5 += runtime.suppressValue((lineno = 56, colno = 48, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_13 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_14 = "";;
frame = frame.pop();
return new runtime.SafeString(t_14);
});
return macro_t_13;})()})])), env.opts.autoescape);
t_5 += "\n                    </a>\n                </div>\n            ";
;
}
t_5 += "\n        </div>\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"showZoom") == "true") {
t_5 += "\n            <div class=\"MemoriesPopup-galleryZoom\">\n                <div class=\"MemoriesPopup-galleryZoomInner\" data-gallery>\n                    ";
frame = frame.push();
var t_17 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery");
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("item", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_5 += "\n                        ";
if(runtime.memberLookup((t_18),"zoom") == "true") {
t_5 += "\n                            <div class=\"MemoriesPopup-galleryZoomItem\">\n                                <div class=\"MemoriesPopup-galleryZoomItemInner\">\n                                    <img data-src=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((t_18),"imageZoom"), env.opts.autoescape);
t_5 += "\">\n                                </div>\n                            </div> \n                        ";
;
}
t_5 += "\n                    ";
;
}
}
frame = frame.pop();
t_5 += "\n                </div>\n                <div class=\"MemoriesPopup-galleryZoomClose Cursor-close\">\n                    ";
t_5 += runtime.suppressValue((lineno = 75, colno = 44, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-close",runtime.makeKeywordArgs({"caller": (function (){var macro_t_19 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_20 = "";;
frame = frame.pop();
return new runtime.SafeString(t_20);
});
return macro_t_19;})()})])), env.opts.autoescape);
t_5 += "\n                </div>\n                ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "block")),"gallery")) > 1) {
t_5 += "\n                    <div class=\"MemoriesPopup-galleryZoomNav\">\n                        <a href=\"\" class=\"MemoriesPopup-galleryZoomNavPrev\" data-gallery-prev>\n                            ";
t_5 += runtime.suppressValue((lineno = 80, colno = 52, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_21 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_22 = "";;
frame = frame.pop();
return new runtime.SafeString(t_22);
});
return macro_t_21;})()})])), env.opts.autoescape);
t_5 += "\n                        </a>\n                        <a href=\"\" class=\"MemoriesPopup-galleryZoomNavNext\" data-gallery-next>\n                            ";
t_5 += runtime.suppressValue((lineno = 83, colno = 52, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-next",runtime.makeKeywordArgs({"caller": (function (){var macro_t_23 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_24 = "";;
frame = frame.pop();
return new runtime.SafeString(t_24);
});
return macro_t_23;})()})])), env.opts.autoescape);
t_5 += "\n                        </a>\n                    </div>\n                ";
;
}
t_5 += "\n            </div>\n        ";
;
}
t_5 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("MemoriesPopupGallery");
context.setVariable("MemoriesPopupGallery", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/MemoriesPopupGallery/MemoriesPopupGallery.jinja"] , dependencies)