var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/django/blindsight.space/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Header/Header.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Header/Header.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_5 = "";t_5 += "\n    <header class=\"Header\">\n        <div class=\"Header-openMenu\">Menu</div>\n        <div class=\"Header-menuWrapper\">\n            <div class=\"Header-closeMenu\">\n                ";
t_5 += runtime.suppressValue((lineno = 7, colno = 40, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["i-close",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "\n            </div>\n            <nav class=\"Header-menu\">\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 10, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:index"])), env.opts.autoescape);
t_5 += "\" data-id=\"index\" class=\"Header-menuItem u-Route\">\n                    Blindsight\n                    <span class=\"Header-menuItemSeparator\"></span>\n                </a>\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 14, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:memories"])), env.opts.autoescape);
t_5 += "\" data-id=\"memories\" class=\"Header-menuItem u-Route\">\n                    Memories\n                    <span class=\"Header-menuItemSeparator\"></span>\n                </a>\n                <a href=\"";
t_5 += runtime.suppressValue((lineno = 18, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:about"])), env.opts.autoescape);
t_5 += "\" data-id=\"about\" class=\"Header-menuItem u-Route\">\n                    About\n                    <span class=\"Header-menuItemSeparator\"></span>\n                </a>\n            </nav>\n            <div class=\"Header-menuButtonWrapper\">\n                <a href=\"\" class=\"Header-menuButton\">Watch Film</a>\n            </div>\n        </div>\n    </header>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("Header");
context.setVariable("Header", macro_t_4);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Header/Header.jinja"] , dependencies)